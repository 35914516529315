<template>
  <component
    :is="props.containerTag"
    data-el="ui-checkbox"
    :for="props.id"
    class="flex items-start"
    :tabindex="props.indeterminate ? 0 : undefined"
    :role="props.indeterminate ? 'checkbox' : undefined"
    :aria-checked="props.indeterminate ? 'mixed' : undefined"
    :class="[
      {
        'cursor-default opacity-70 grayscale': props.disabled,
        'cursor-pointer': !props.disabled,
      },
      ...props.containerClass,
    ]"
  >
    <input
      :id="props.id"
      v-bind="$attrs"
      :key="String(props.modelValue && props.indeterminate)"
      v-model="inputValue"
      :indeterminate="props.indeterminate"
      :name="props.name"
      class="rounded border-2 bg-transparent"
      :class="[
        {
          'mr-3.5': label || $slots.default,
          'cursor-default': props.disabled,
          'cursor-pointer': !props.disabled,
          'border-primary': props.variant === Variant.PRIMARY,
          'border-secondary': props.variant === Variant.SECONDARY,
          'border-blue text-blue': props.variant === Variant.NAVY,
          'size-[1.875rem]': props.size === Size.BIG,
          'size-5': props.size === Size.SMALL,
          'border-notification-error': props.error,
        },
      ]"
      :aria-label="props.label"
      :disabled="props.disabled"
      type="checkbox"
      @change="onChange"
    >

    <slot>
      <span
        v-if="props.label"
        class="select-none"
      >
        {{ props.label }}
      </span>
    </slot>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { UiFormFieldRules } from '../UiForm.types'
import type { SizeVariantType, VariantType } from './UiCheckbox.types'
import { Size, Variant } from './UiCheckbox.types'

interface UiCheckbox {
  id?: string
  label?: string
  rules?: UiFormFieldRules
  name?: string
  error?: boolean
  modelValue?: any
  indeterminate?: boolean
  variant?: VariantType
  size?: SizeVariantType
  disabled?: boolean
  classes?: string
  containerTag?: keyof HTMLElementTagNameMap
  containerClass?: Array<(string | Record<string, boolean>)>
}

const props = withDefaults(defineProps<UiCheckbox>(), {
  id: '',
  label: '',
  rules: '',
  modelValue: '',
  indeterminate: false,
  name: '',
  error: false,
  variant: 'secondary',
  size: 'big',
  disabled: false,
  classes: '',
  containerTag: 'label',
  containerClass: () => [],
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void
  (e: 'change', value: Event): void
}>()

const inputValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

function onChange(event: Event) {
  emit('change', event)
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
