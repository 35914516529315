export enum Size {
  BIG = 'big',
  SMALL = 'small',
}

export type SizeVariantType = `${Size}`

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  NAVY = 'navy',
}

export type VariantType = `${Variant}`
